import React from "react";
import loadable from "@loadable/component";

const DefaultPageContainer = loadable(() =>
  import("@templates/DefaultPageContainer")
);
const StoryOfChange = loadable(() => import("@templates/StoryOfChange"));
const Frame = loadable(() => import("@templates/Frame"));

const Story = ({ data }) => {
  const { title } = data;
  return (
    <DefaultPageContainer>
      <Frame title={title} />
      <article className="py-14">
        <StoryOfChange {...data} />
      </article>
    </DefaultPageContainer>
  );
};

export default Story;
