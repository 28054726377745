import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "@pageTemplates/Story";
import resolveStory from "@dataResolvers/resolveStory";
// eslint-disable-next-line no-unused-vars
import StoriesFragment from "@fragments/StoriesFragment";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query StoriesOfChange($uri: [String], $siteId: [Craft_QueryArgument]) {
    craft {
      entry(section: "storiesOfChange", uri: $uri, siteId: $siteId) {
        ...StoriesFragment
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  return resolveStory(entry);
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="StoriesOfChangeDefaultQuery"
    {...props}
  />
);

export default Page;
